export const config = {
  performance: {
    title: 'Performance Chart',
    desc: `De grafiek laat de performance zien over periodes naar keuze en t.o.v. benchmarks naar keuze, zoals de 60/40 Index portefeuille (zie hiervoor '<a href='https://rammfunds.nl/faq?faq=27'>Toelichting gebruikte termen</a>' bij FAQ.)`,
  },
  buttonLabels: ['1M', '3M', '6M', '1Y', '3Y', '5Y'],
  allTime: 'All Time',
  custom: 'Custom',
  draw: {
    title: 'Drawdown Chart',
    desc:
      'De grafiek laat de maximum drawdown zien over tijd. De maximum drawdown is de procentuele koersdaling gemeten vanaf het hoogste niveau dat in de voorgaande periode is bereikt. De grafiek laat zien dat de drawdown van RAMM in perioden van stress veel beperkter was dan die van een Statische Mix of 60/40 Index portefeuille.',
  },
  table: {
    title: 'Performance table',
    desc: 'Maandelijkse performance in euro’s.',
  },
  tab: {
    one: 'Chart',
    two: 'Table',
  },
}
